<div class="card border h-100">
    <div class="card-body d-flex flex-column justify-content-center align-items-center">
        <div [ngStyle]="{ 'background-color': 'var(--' + backgroundClass + ')' }"
            class="icon d-flex justify-content-center align-items-center mb-2 p-2 text-white rounded">
            <mat-icon>{{ iconName }}</mat-icon>
        </div>
        <h5 class="card-title mb-2 text-center fw-bold">{{ dataTitle }}</h5>
        <h6 class="text-center mb-0">{{ dataSubtitle }}</h6>
        <p class="text-muted text-center mb-0 mt-2">{{ dataDescription }}</p>
    </div>
</div>