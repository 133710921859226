import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-info',
  standalone: false,
  templateUrl: './card-info.component.html',
  styleUrl: './card-info.component.scss'
})
export class CardInfoComponent {

  @Input() numericData: any = 0;
  @Input() dataName: string = '';
  @Input() iconName: string = '';
  @Input() backgroundClass: string = 'primary-alternative-dark';
  @Input() textClass: string = '';
  @Input() variationType: 'type1' | 'type2' = 'type1';

}
