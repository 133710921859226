import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-simple',
  standalone: false,
  templateUrl: './card-simple.component.html',
  styleUrl: './card-simple.component.scss'
})
export class CardSimpleComponent {

  @Input() dataTitle: string = '';
  @Input() dataSubtitle: string = '';
  @Input() dataDescription: string = '';
  @Input() iconName: string = '';
  @Input() backgroundClass: string = '';

}
