import { Component } from '@angular/core';

@Component({
  selector: 'app-module-aloc',
  standalone: false,
  templateUrl: './module-aloc.component.html',
  styleUrl: './module-aloc.component.scss'
})
export class ModuleAlocComponent {

}
