<div class="card py-3 border-0 h-100" 
     [style.background-color]="'var(--' + backgroundClass + ')'" 
     [style.color]="'var(--' + textClass + ')'">
    <div class="card-body d-flex flex-column flex-md-row justify-content-between align-items-center h-100">

        <!-- Type 1 -->
        <div *ngIf="variationType === 'type1'" 
             class="d-flex flex-column flex-md-row justify-content-between align-items-center w-100">

            <div class="icon-circle d-flex justify-content-center align-items-center order-1 order-md-2 mb-2 mb-md-0">
                <mat-icon>{{ iconName }}</mat-icon>
            </div>

            <div class="text-center text-md-start order-2 order-md-1">
                <h6 class="card-title mb-1">{{ dataName }}</h6>
                <h3 class="card-subtitle mb-0">{{ numericData }}</h3>
            </div>
        </div>

        <!-- Type 2 -->
        <div *ngIf="variationType === 'type2'" 
             class="d-flex flex-column flex-md-row justify-content-between align-items-center w-100">

            <div class="icon-circle d-flex justify-content-center align-items-center order-1 order-md-3 mb-2 mb-md-0">
                <mat-icon>{{ iconName }}</mat-icon>
            </div>

            <h6 class="card-title mb-0 order-2 order-md-1 text-center text-md-start">{{ dataName }}</h6>
            <h3 class="card-subtitle mb-0 order-3 order-md-2 text-center text-md-start">{{ numericData }}</h3>
        </div>
    </div>
</div>
