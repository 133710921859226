<p>Bem-vindo(a)!</p>

<app-acord-filter></app-acord-filter>




<div style="max-width: 500px;">
<app-acord-filter [configFiltro]=""></app-acord-filter>
</div>

