import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-icon',
  standalone: false,
  templateUrl: './card-icon.component.html',
  styleUrl: './card-icon.component.scss'
})
export class CardIconComponent {

  @Input() small: boolean = false;
  @Input() dataName: string = '';
  @Input() iconName: string = '';
  @Input() backgroundClass: string = '';
  @Input() textClass: string = '';
  @Input() routeLink: string | null = null;

}
