<mat-sidenav-container>
  <mat-sidenav #sidenav
    [mode]="mobileQuery?.matches ? 'over' : 'side'"
    opened>
    <a class="app-logo">
      <img src="assets/images/aem_performance.png"
        width="200"
        alt="Logotipo da A&M" />
    </a>
    <div class="mat-nav-list-content">
      <mat-nav-list>
        <a class="mat-mdc-list-item"
          [routerLink]="'/' + item.routLink"
          routerLinkActive="mat-mdc-list-item--active"
          mat-list-item
          *ngFor="let item of menuItens">
          <ng-container *ngIf="item.icon">
            <mat-icon class="menu-icon">{{ item.icon }}</mat-icon>
          </ng-container>
          {{ item.title }}
        </a>
      </mat-nav-list>

      <mat-nav-list class="logout-action">
        <a class="mat-mdc-list-item mat-mdc-list-item--logout"
          mat-list-item
          (click)="logout()"
          href="javascript:">
          <mat-icon>logout</mat-icon>
          <span>Sair</span>
        </a>
      </mat-nav-list>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <header class="app-header">
      <button mat-icon-button
        (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <ng-container *ngIf="false">
        <div class="header-search">
          <!-- <mat-icon class="header-search-icon" fontIcon="search"></mat-icon> -->
          <input class="header-search-input"
            type="text" />
        </div>
        <div class="header-actions">
          <div class="notifications">
            <mat-icon outline
              fontIcon="notifications"></mat-icon>
          </div>
          <div class="user">
            <img src="assets/images/avatar-empty.svg"
              alt="Avatar do usuário"
              [matMenuTriggerFor]="menu"
              aria-label="Menu do usuário" />
            <mat-menu #menu="matMenu">
              <button mat-menu-item
                (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Sair</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </ng-container>
    </header>

    <section class="page-frame"
      role="main">
      <div class="wrapper">
        <router-outlet></router-outlet>
      </div>

      <footer class="app-footer">
        <p>&copy; A&M</p>
        <a href="javascript:">Política de Privacidade</a>
      </footer>
    </section>

  </mat-sidenav-content>
</mat-sidenav-container>