<table class="table-default" mat-table [dataSource]="dataSource" matSort matSortActive="idProjeto"
    matSortDirection="desc">
    <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let item" data-label="Nome">
            <span>{{ item.nome }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="chave">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chave</th>
        <td mat-cell *matCellDef="let item" data-label="Chave">
            <span>{{ item.chave }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="detalhes">
        <th mat-header-cell *matHeaderCellDef>Detalhes</th>
        <td mat-cell *matCellDef="let item" data-label="Detalhes">
          <a
            class="btn-link"
            [href]="item.url"
            target="_blank"
          >
            <mat-icon> chevron_right </mat-icon>
          </a>
        </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let item; columns: columns"></tr>
</table>

<mat-paginator class="paginator-default" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
    aria-label="Select page of periodic elements">
</mat-paginator>