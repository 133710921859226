<!-- <div class="example-action-buttons">
    <button mat-button (click)="accordion().openAll()">Expand All</button>
    <button mat-button (click)="accordion().closeAll()">Collapse All</button>
  </div> -->
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> {{titleFilter}} </mat-panel-title>
        <mat-panel-description>
            {{subTitleFilter}}
          <!-- <mat-icon>account_circle</mat-icon> -->
        </mat-panel-description>
      </mat-expansion-panel-header>
  


      <div>
        <mat-form-field *ngFor="let item of configFiltro" class="fcolor pl-20 pr-20 acord-filter">
    
            <mat-label>{{item.title}}</mat-label>
            <input *ngIf="item.tipo != 'select'" autocomplete="off" [type]="item.tipo" [(ngModel)]="filterValue[item.campo]" (keydown.enter)="keyEnter()"
                matInput placeholder="{{item.title}}" class="search-input">
    
                <!-- (change)="changeCriador($event)" -->
            <mat-select *ngIf="item.tipo == 'select' && item.opcoes" #selectedx  [(ngModel)]="filterValue[item.campo]"
                [ngModelOptions]="{standalone: true}">
                <mat-option value="">Selecione</mat-option>
    
                <mat-option  *ngFor="let op of item.opcoes" [value]="op.id">{{op.desc}}</mat-option>
            </mat-select>
        </mat-form-field>
    
    
    
        <button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Limpar" (click)="limparCampo()">
            <mat-icon>close</mat-icon>
        </button>
        <button (click)="keyEnter()" class="tableHeadBtn" matSuffix mat-icon-button aria-label="Pesquisar">
            <mat-icon class="ajust-font-icon ">search</mat-icon>
        </button>
    
    </div>



      
    </mat-expansion-panel>
    
  
    
  </mat-accordion>



