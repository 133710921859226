<div class="card h-100" [ngStyle]="{ 'border-color': 'var(--' + color + ')' }">

    

    <div class="card-header d-flex justify-content-center flex-column align-items-center mb-3">
        <span *ngIf="isCurrent === 1" class="py-2 px-3 mb-2 rounded text-white fw-bold" [ngStyle]="{ 'background-color': 'var(--' + color + ')' }">Alocação atual</span>

        <img *ngIf="logoUrl" [src]="logoUrl" alt="{{ projectName }} Logo" class="logo">
    </div>
    <div class="card-body">
        <div class="d-flex align-items-center gap-3 p-2">
            <mat-icon [ngStyle]="{ 'color': 'var(--' + color + ')' }">home</mat-icon>
            <div>
                <p class="card-text mb-0" [ngStyle]="{ 'color': 'var(--' + color + ')' }"><strong>Projeto:</strong> {{
                    projectName }}</p>
                <p class="card-text mb-0"><strong>Código: </strong> <strong>{{ projectCode }}</strong></p>
            </div>
        </div>

        <div class="bg-gray d-flex align-items-center gap-3 p-2">
            <mat-icon [ngStyle]="{ 'color': 'var(--' + color + ')' }">date_range</mat-icon>
            <div class="d-flex align-items-center gap-4">
                <div>
                    <p class="card-text mb-0"><strong>Dt inicial:</strong></p>
                    <p class="card-text text-muted mb-0">{{ startDate | date:'dd/MM/yyyy' }}</p>
                </div>

                <div>
                    <p class="card-text mb-0"><strong>Dt final:</strong></p>
                    <p class="card-text text-muted mb-0">{{ endDate | date:'dd/MM/yyyy' }}</p>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center gap-3 p-2">
            <mat-icon [ngStyle]="{ 'color': 'var(--' + color + ')' }">schedule</mat-icon>
            <div>
                <p class="card-text mb-0"><strong>Status:</strong></p>
                <p class="card-text text-muted mb-0">{{ status }}</p>
            </div>
        </div>


        <div class="bg-gray d-flex align-items-center gap-3 p-2">
            <mat-icon [ngStyle]="{ 'color': 'var(--' + color + ')' }">domain</mat-icon>
            <div>
                <p class="card-text mb-0"><strong>Setor:</strong></p>
                <p class="card-text text-muted mb-0">{{ sector }}</p>
            </div>
        </div>

        <div class="d-flex align-items-center gap-3 p-2">
            <mat-icon [ngStyle]="{ 'color': 'var(--' + color + ')' }">inventory_2</mat-icon>
            <div>
                <p class="card-text mb-0"><strong>Produto:</strong></p>
                <p class="card-text text-muted mb-0">{{ product }}</p>
            </div>
        </div>

    </div>
</div>