import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';

@Component({
  selector: 'app-acord-filter',
  templateUrl: './acord-filter.component.html',
  styleUrls: ['./acord-filter.component.sass']
})
export class AcordFilterComponent implements OnInit {

  constructor() { }
  filterValue={}
  @Input()
  placeholder = "Pesquisar"

  @Input()
  titleFilter = "Titulo da pesquisa"

  @Input()
  subTitleFilter = ""

  @Input()
  configFiltro :Array<ConfigFilter> = [{"title":"Filtro x", "campo":"filtorx", "tipo": "number"},{"title":"Filtro 1", "campo":"filtor1", "tipo": "date"}, {"title":"Filtro 2", "campo":"filtro2", tipo:"text"}, {"title":"Filtro 3", "campo":"filtro3", tipo:"select", opcoes:[{id:"1", desc:"desc 1"}, {id:"2", desc:"desc 2"}]}]
  @Output()
  onEnterr: EventEmitter<object> = new EventEmitter<object>();
  
  

  
  
  limparCampo() {
    this.filterValue = {};
    this.keyEnter()
   }

  keyEnter() {
    console.log(this.filterValue)
    this.onEnterr.emit(this.filterValue)
  }

  ngOnInit(): void {
  }

  

}
export interface SelectOptions {
  id:string | number, 
  desc:string
}

export interface ConfigFilter {
  title: string, 
  campo: string, 
  tipo: "select" | "number" |"email"| "tel" | "text" | "date"| "button" | "checkbox" | "color" | "date" | "datetime-local" | "email" | "file" | "hidden" | "image" | "month" | "number" | "password" | "radio" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" | "url" | "week" , 
  opcoes?:Array<SelectOptions> 
}
